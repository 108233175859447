<template>
  <div class="playbook-org-context-selector">
    <a-select
      size="large"
      style="width: 350px"
      option-filter-prop="children"
      show-search
      :filter-option="filterOption"
      v-model="owner_id_filter"
    >
      <a-select-option
        v-for="option in orgOptions"
        :value="option.value"
        :label="option.label"
        :key="option.value"
      >
        <span role="img">
          <organisation-thumbnail
            :tenant-id="tenantId"
            :org-id="option.value"
            style="display: inline; margin-right: 13px"
            size="small"
            :key="ownerIdFilter ? ownerIdFilter : 100"
          ></organisation-thumbnail>
        </span>

        {{ option.label }}
      </a-select-option>
      <a-select-option
        v-if="guidancePackOptions.length || isLoadingGuidancePacks"
        :value="null"
        disabled
      >
        <span role="img"></span>
        Guidance Packs</a-select-option
      >
      <a-select-option v-if="isLoadingGuidancePacks" :value="null" disabled>
        <span role="img"></span>
        Guidance Packs</a-select-option
      >
      <a-select-option
        v-for="option in guidancePackOptions"
        :key="option.value"
      >
        <span role="img">
          <organisation-thumbnail
            :tenant-id="tenantId"
            :org-id="option.value"
            style="display: inline; margin-right: 13px"
            size="small"
            :key="ownerIdFilter ? ownerIdFilter : 100"
          ></organisation-thumbnail>
        </span>

        {{ option.label }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import OrganisationThumbnail from "../../../components/Organisations/OrganisationThumbnail.vue";
import { mapGetters } from "vuex";
const _ = require("lodash");
export default {
  props: ["ownerIdFilter"],

  methods: {
    setOwnerIdFilter(val) {
      this.$emit("set-owner-id-filter", val);
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },

  components: { OrganisationThumbnail },

  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
    }),

    ...mapGetters('adminGuidance', {
        ownerIdFilterOptions: 'ownerIdFilterOptions',
        isLoadingGuidancePacks: 'isLoadingGuidancePacks'
    }),

    owner_id_filter: {
      get() {
        return this.ownerIdFilter;
      },
      set(val) {
        this.setOwnerIdFilter(val);
      },
    },

    orgOptions() {
      return _.filter(this.ownerIdFilterOptions, {
        type: "organisation",
      });
    },

    guidancePackOptions() {
      let vm = this;
      return _.filter(this.ownerIdFilterOptions, (option) => {
        return (
          option.type == "guidance-pack" &&
          option.value !== vm.selectedOrganisation.id
        );
      });
    },
  },
};
</script>

<style lang="scss">
.playbook-org-context-selector {
  .ant-select-lg .ant-select-selection--single {
    height: 55px;
    border: 0;
  }

  .ant-select-lg .ant-select-selection__rendered {
    line-height: 52px;
  }
}
</style>