<template>
  <div class="incident-type-list-item" @click.prevent="$emit('selected')">
    <div class="left">
      <scope-icon :scope="incidentType.scope"></scope-icon>
    </div>
    <div class="right">
        <div class="name">
            {{ incidentType.displayName }}
        </div>
        <div class="properties" v-if="!hideProperties">
            <div v-if="group" class="property">
                <span>Group:</span> {{ group.displayName }}
            </div>
            <div class="property" v-if="!group && sos && !hideProperties">
             <span>Group:</span> SOS
            </div>
            <div class="property" v-if="skill">
                <span>Response Team:</span> {{ skill.displayName }}
            </div>
        </div>
    </div>
  </div>
</template>

<script>
const _ = require("lodash");
import ScopeIcon from "../../../../components/ScopeIcon.vue";
export default {
  props: ["incidentType", "tenantId", "orgId", "allIncidentTypes", "skills", "hideProperties", "incidentTypeListItem", "sos"],
  components: { ScopeIcon },
  computed: {
    group() {
        if (this.incidentType.incidentTypeGroupId) {
            return _.find(this.allIncidentTypes, {id: this.incidentType.incidentTypeGroupId});
        }
        return null;
    },
    skill() {
        if (this.incidentType.skillId && this.skills) {
            return _.find(this.skills, {id: this.incidentType.skillId});
        }
        return null;
    }
  }
};
</script>

<style scoped lang="scss">
.incident-type-list-item {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #fff;

  display: flex;

  .left {
    flex-shrink: 1;
    min-width: 35px;
    display: flex;
    align-items: center;
  }

  .right {
    flex-grow: 1;

    .name {
      font-weight: 500;
    }

    .property {
        margin-top: 5px;
        span {
            font-weight: 500;
        }
    }
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }
}
</style>